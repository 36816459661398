body{
    background-color: var(--ion-color-light);
}


/* styles for desktop chats components */
.chats-desktop-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
}
.chats-desktop-sidebar{
    width: 25%;
    height: 100%;
}

.chats-desktop-content{
    width: 75%;
    min-height: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6px;
    padding-bottom: 64px;
    overflow-y: auto;
}
.chats-desktop-window{
    box-sizing: border-box;
    position: relative;
    padding: 6px;
}
.chat-window-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0px;
}
.chat-window-header{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    height: 42px;
    background-color: var(--ion-color-primary);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    color: var(--ion-color-primary-contrast);
}
.chat-window-header p{
    margin-right: auto;
}
.chat-window-content{
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    padding-bottom: 96px;
    padding-top: 24px;
    scroll-behavior: smooth;
    background-color: var(--ion-color-primary-contrast);
    box-shadow: 0px 0px 12px rgba(0,0,0,0.1);
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
}
.chat-window-content.isWindow{
    padding: 58px 0px;
}
.chat-window-footer{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    width: 100%;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--ion-color-light);
    box-shadow: 0px 4px 12px var(--ion-color-medium);
    background-color: var(--ion-color-dark-contrast);
}
.message-back-to-bottom-btn{
    display: none;
    position: absolute;
    bottom: 64px;
    left: 0px;
    width: 100%;
    padding: 12px;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.message-back-to-bottom-btn.show{
    display: flex;
}
.message-back-to-bottom-btn > .btn{
    background-color: var(--ion-color-primary);
    padding: 8px 24px;
    color: var(--ion-color-primary-contrast);
    border-radius: 99px;
}
.message-loading-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

@media only screen and (min-width: 1280px) {
    .chats-desktop-sidebar{
        width: 20%;
    }
    .chats-desktop-content{
        width: 80%;
    }
}