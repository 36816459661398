.status-dot{
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 99px;
    /* border: 2px solid var(--ion-color-dark-contrast); */
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.status-dot.online{
    background: var(--ion-color-success);
}
.status-dot.offline{
    background: var(--ion-color-danger);
}
.status-dot.hidden{
    background: var(--ion-color-warning);
}