.message-divider{
    text-align: center;
    min-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.message-divider-content{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-light);
    border-radius: 99px;
    padding: 4px 12px;
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    font-weight: bold;
}
.message-bubble-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.message-bubble-container.self{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.message-top-layer{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 75%;
}
.self .message-top-layer{
    flex-direction: row-reverse;
}
.message-sender-wrapper{
    display: flex;
    flex-direction: row;
    margin-left: unset;
    margin-right: 12px;
    align-items: center;
}
.self .message-sender-wrapper{
    margin-left: 12px;
    margin-right: unset;
    flex-direction: row-reverse;
}
.message-sender-wrapper .sender-info{
    display: flex;
    flex-direction: column;
    margin: 0px 12px;
    align-items: flex-start;
}
.self .message-sender-wrapper .sender-info{
    align-items: flex-end;
}

.message-bubble{
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    padding: 6px 16px;
    max-width: 100%;
    border-radius: 6px;
    min-width: 74px;
    position: relative;
    margin: 0px 12px;
    box-sizing: border-box;
}
.message-bubble a{
    color: var(--ion-color-secondary-contrast);
}
.message-bubble.isTop{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.message-bubble.isBottom{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.self .message-bubble{
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    align-self: flex-end;
}
.self .message-bubble a{
    color: var(--ion-color-primary-contrast);
}
.messaage-time{
    display: block;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    margin-top: 6px;
    margin-bottom: 24px;
}
.arrow{
    stroke: none;
    stroke-width: 0%;
    z-index: -1;
}
.arrow > path{
    fill: var(--ion-color-secondary);
}

.self .arrow > path{
    fill: var(--ion-color-primary);
}
.arrow{
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 0px;
    left: -8px;
}
.self .arrow{
    bottom: 0px;
    left: unset;
    right: -8px;
    transform: scaleX(-1);
}
.message-bottom-layer{
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
    margin-top: 2px;
    align-items: flex-start;
    width: 80%;
}
.self .message-bottom-layer{
    align-items: flex-end;
}
.sender-name{
    font-size: 12px;
}
.memo-bubble{
    min-height: auto;
}
.memo-bubble.isHidden{
    display: none;
}
.memo-bubble.isModerator.isHidden{
    display: block;
}
.memo-bubble.isModerator.isHidden .message-bubble{
    opacity: 0.3;
}
.sponsor-badge{
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    padding: 0px 8px;
    border-radius: 99px;
    margin: 0px 0px;
}
.sender-name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.self .sender-name{
    align-items: flex-end;
}