.profile-avatar-lg{
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
    margin: auto;
}

.profile-avatar-xl{
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    margin: auto;
}