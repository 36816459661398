.phone-image-container{
    position: relative;
    overflow: hidden;
}
.phone-image-container .phone-image{
    width: 100%;
    height: auto;
}
.phone-image-container .content-image{
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transition: 300ms;
    animation-name: slideUp;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes slideUp {
    0%{
        top: 50%;
    }
    15%{
        top: 50%;
    }
    40%{
        top: 0%;
    }
    100%{
        top: 0%;
    }
}