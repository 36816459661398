.videoCall {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    user-select: none;
    
    &__title {
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    &__name {
        font-size: 1.6em;
        margin-bottom: 10px;
    }

    &__jobTitle {
        font-size: 1em;
        margin-bottom: 30px;
    }

    &__buttonsArea{
        margin: 30px;
        display: flex;
        flex-direction: row;

        > div {
            margin: 0px 15px;
        }
    }

    &__iframe {
        height: 100%;
        width:100%;
        position: absolute;
        border: none;
    }

    &__dolbyButtons {
        position: absolute;
        left: 50%;
        transform: translate(-50% ,0);
        top: -16px;
        text-align: center;
        overflow: hidden;

        .-callHangup {
            background-color: rgb(235, 16, 0)!important;
            height: 30px;
            width: 30px;
            border: 2px solid black;
            padding: 10px;
            bottom: -10px;
            position: relative;
        }

        .videoCall__buttonLabel {
            font-size: 11px;
            position: relative;
            top: -5px;
            display: none;
        }

        @media only screen and (max-width: 766px) {
            .-callHangup {
                height: 25px;
                width: 25px;
                padding: 6px;
                bottom: -13px;         
            }
        }

        @media only screen and (min-width: 875px) {
            .videoCall__buttonLabel {
                display: block;
            }
        }
    }

    &__button {
        background-color: var(--ion-color-primary-contrast);
        color: var(--ion-color-primary);
        border-radius: 50%;
        height: 45px;
        width: 45px;
        display: inline-block;
        justify-content: center;
        align-items: center;
        padding: 15px;
        margin: 10px;

        &.-callOutline {
            background-color: rgb(48, 208, 88);
        }

        &.-callHangup {
            background-color: rgb(253, 58, 49);
            transform: rotate(135deg);
        }

        &:hover {
            opacity: 0.9;
            cursor: pointer;
        }

        &:active {
            opacity: 1;
            filter: brightness(1.1)
        }
    }
}