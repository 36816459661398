ion-select.matchmaking-select {
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
    border-radius: 4px;
}

/* css for dropdown to show values fully */
.alert-radio-group.sc-ion-alert-ios,
.alert-checkbox-group.sc-ion-alert-ios {
    overflow-y: auto !important;
}

.alert-tappable {
    height: auto !important;
    contain: content !important;
}

.alert-button-inner {
    height: max-content !important;
}

.alert-checkbox-label {
    height: fit-content !important;
    white-space: unset !important;
    text-overflow: unset !important;
    overflow: visible !important;
}

.sc-ion-alert-ios-h {
    --max-width: 90% !important;
}

.action-sheet-button.sc-ion-action-sheet-ios {
    contain: content !important;
    height: fit-content;
}

ion-select::part(text) {
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: hidden !important;
}

ion-radio {
    --border-radius: 4px;
    --inner-border-radius: 4px;

    --color: #ddd;
    --color-checked: #6815ec;
}

ion-radio.ios {
    width: 20px;
    height: 20px;

    border: 2px solid #ddd;
    border-radius: 4px;
}

.radio-checked.ios {
    border-color: #6815ec;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: visible !important;
}