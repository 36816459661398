@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap);
.status-dot{
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 99px;
    /* border: 2px solid var(--ion-color-dark-contrast); */
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.status-dot.online{
    background: var(--ion-color-success);
}
.status-dot.offline{
    background: var(--ion-color-danger);
}
.status-dot.hidden{
    background: var(--ion-color-warning);
}
.message-divider{
    text-align: center;
    min-height: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.message-divider-content{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-light);
    border-radius: 99px;
    padding: 4px 12px;
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    font-weight: bold;
}
.message-bubble-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.message-bubble-container.self{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.message-top-layer{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 75%;
}
.self .message-top-layer{
    flex-direction: row-reverse;
}
.message-sender-wrapper{
    display: flex;
    flex-direction: row;
    margin-left: unset;
    margin-right: 12px;
    align-items: center;
}
.self .message-sender-wrapper{
    margin-left: 12px;
    margin-right: unset;
    flex-direction: row-reverse;
}
.message-sender-wrapper .sender-info{
    display: flex;
    flex-direction: column;
    margin: 0px 12px;
    align-items: flex-start;
}
.self .message-sender-wrapper .sender-info{
    align-items: flex-end;
}

.message-bubble{
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    padding: 6px 16px;
    max-width: 100%;
    border-radius: 6px;
    min-width: 74px;
    position: relative;
    margin: 0px 12px;
    box-sizing: border-box;
}
.message-bubble a{
    color: var(--ion-color-secondary-contrast);
}
.message-bubble.isTop{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.message-bubble.isBottom{
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.self .message-bubble{
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    align-self: flex-end;
}
.self .message-bubble a{
    color: var(--ion-color-primary-contrast);
}
.messaage-time{
    display: block;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    margin-top: 6px;
    margin-bottom: 24px;
}
.arrow{
    stroke: none;
    stroke-width: 0%;
    z-index: -1;
}
.arrow > path{
    fill: var(--ion-color-secondary);
}

.self .arrow > path{
    fill: var(--ion-color-primary);
}
.arrow{
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 0px;
    left: -8px;
}
.self .arrow{
    bottom: 0px;
    left: unset;
    right: -8px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.message-bottom-layer{
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
    margin-top: 2px;
    align-items: flex-start;
    width: 80%;
}
.self .message-bottom-layer{
    align-items: flex-end;
}
.sender-name{
    font-size: 12px;
}
.memo-bubble{
    min-height: auto;
}
.memo-bubble.isHidden{
    display: none;
}
.memo-bubble.isModerator.isHidden{
    display: block;
}
.memo-bubble.isModerator.isHidden .message-bubble{
    opacity: 0.3;
}
.sponsor-badge{
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    padding: 0px 8px;
    border-radius: 99px;
    margin: 0px 0px;
}
.sender-name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.self .sender-name{
    align-items: flex-end;
}
.phone-image-container{
    position: relative;
    overflow: hidden;
}
.phone-image-container .phone-image{
    width: 100%;
    height: auto;
}
.phone-image-container .content-image{
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transition: 300ms;
    -webkit-animation-name: slideUp;
            animation-name: slideUp;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

@-webkit-keyframes slideUp {
    0%{
        top: 50%;
    }
    15%{
        top: 50%;
    }
    40%{
        top: 0%;
    }
    100%{
        top: 0%;
    }
}

@keyframes slideUp {
    0%{
        top: 50%;
    }
    15%{
        top: 50%;
    }
    40%{
        top: 0%;
    }
    100%{
        top: 0%;
    }
}
.message-input-emoji-picker{
    position: absolute;
    bottom: 48px;
    left: 0px;
    width: 100%;
    display: none;
}
.message-input-emoji-picker.open{
    display: unset;
}


.grouped-question-popover{
    background-color: rgba(0,0,0,0.3);
}
.grouped-question-popover .popover-content{
    width: 80%;
}
.grouped-question-radio-group ion-list-header ion-label{
    font-size: 16px;
    margin-top: 16px;
}
ion-select.matchmaking-select {
    width: 100%;
    max-width: 100% !important;
    min-width: 100% !important;
    border-radius: 4px;
}

/* css for dropdown to show values fully */
.alert-radio-group.sc-ion-alert-ios,
.alert-checkbox-group.sc-ion-alert-ios {
    overflow-y: auto !important;
}

.alert-tappable {
    height: auto !important;
    contain: content !important;
}

.alert-button-inner {
    height: -webkit-max-content !important;
    height: max-content !important;
}

.alert-checkbox-label {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    white-space: unset !important;
    text-overflow: unset !important;
    overflow: visible !important;
}

.sc-ion-alert-ios-h {
    --max-width: 90% !important;
}

.action-sheet-button.sc-ion-action-sheet-ios {
    contain: content !important;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

ion-select::part(text) {
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: hidden !important;
}

ion-radio {
    --border-radius: 4px;
    --inner-border-radius: 4px;

    --color: #ddd;
    --color-checked: #6815ec;
}

ion-radio.ios {
    width: 20px;
    height: 20px;

    border: 2px solid #ddd;
    border-radius: 4px;
}

.radio-checked.ios {
    border-color: #6815ec;
}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{
    text-overflow: unset !important;
    white-space: normal !important;
    overflow: visible !important;
}
.profile-avatar-lg{
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
    margin: auto;
}

.profile-avatar-xl{
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    margin: auto;
}
ion-avatar.title-icon{
    background: var(--ion-color-success);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 64px;
    max-height: 64px;
    min-width: 64px;
    min-height: 64px;
}
.videoCall{text-align:center;display:flex;align-items:center;justify-content:center;height:100%;flex-direction:column;-webkit-user-select:none;-ms-user-select:none;user-select:none}.videoCall__title{font-size:2.5em;margin-bottom:10px}.videoCall__name{font-size:1.6em;margin-bottom:10px}.videoCall__jobTitle{font-size:1em;margin-bottom:30px}.videoCall__buttonsArea{margin:30px;display:flex;flex-direction:row}.videoCall__buttonsArea>div{margin:0px 15px}.videoCall__iframe{height:100%;width:100%;position:absolute;border:none}.videoCall__dolbyButtons{position:absolute;left:50%;-webkit-transform:translate(-50%, 0);transform:translate(-50%, 0);top:-16px;text-align:center;overflow:hidden}.videoCall__dolbyButtons .-callHangup{background-color:#eb1000 !important;height:30px;width:30px;border:2px solid #000;padding:10px;bottom:-10px;position:relative}.videoCall__dolbyButtons .videoCall__buttonLabel{font-size:11px;position:relative;top:-5px;display:none}@media only screen and (max-width: 766px){.videoCall__dolbyButtons .-callHangup{height:25px;width:25px;padding:6px;bottom:-13px}}@media only screen and (min-width: 875px){.videoCall__dolbyButtons .videoCall__buttonLabel{display:block}}.videoCall__button{background-color:var(--ion-color-primary-contrast);color:var(--ion-color-primary);border-radius:50%;height:45px;width:45px;display:inline-block;justify-content:center;align-items:center;padding:15px;margin:10px}.videoCall__button.-callOutline{background-color:#30d058}.videoCall__button.-callHangup{background-color:#fd3a31;-webkit-transform:rotate(135deg);transform:rotate(135deg)}.videoCall__button:hover{opacity:.9;cursor:pointer}.videoCall__button:active{opacity:1;-webkit-filter:brightness(1.1);filter:brightness(1.1)}
*{
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0rem;
}
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #666666;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #444444;
  --ion-color-primary-tint: #777777;

  /** secondary **/
  --ion-color-secondary: #888888;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #999999;
  --ion-color-secondary-tint: #777777;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** white **/
  --ion-color-white: #f4f5f8;
  --ion-color-white-rgb: 244, 245, 248;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;
}

.ion-color-white{
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
body{
    background-color: var(--ion-color-light);
}


/* styles for desktop chats components */
.chats-desktop-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
}
.chats-desktop-sidebar{
    width: 25%;
    height: 100%;
}

.chats-desktop-content{
    width: 75%;
    min-height: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6px;
    padding-bottom: 64px;
    overflow-y: auto;
}
.chats-desktop-window{
    box-sizing: border-box;
    position: relative;
    padding: 6px;
}
.chat-window-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0px;
}
.chat-window-header{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    height: 42px;
    background-color: var(--ion-color-primary);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    color: var(--ion-color-primary-contrast);
}
.chat-window-header p{
    margin-right: auto;
}
.chat-window-content{
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    padding-bottom: 96px;
    padding-top: 24px;
    scroll-behavior: smooth;
    background-color: var(--ion-color-primary-contrast);
    box-shadow: 0px 0px 12px rgba(0,0,0,0.1);
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
}
.chat-window-content.isWindow{
    padding: 58px 0px;
}
.chat-window-footer{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    width: 100%;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--ion-color-light);
    box-shadow: 0px 4px 12px var(--ion-color-medium);
    background-color: var(--ion-color-dark-contrast);
}
.message-back-to-bottom-btn{
    display: none;
    position: absolute;
    bottom: 64px;
    left: 0px;
    width: 100%;
    padding: 12px;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.message-back-to-bottom-btn.show{
    display: flex;
}
.message-back-to-bottom-btn > .btn{
    background-color: var(--ion-color-primary);
    padding: 8px 24px;
    color: var(--ion-color-primary-contrast);
    border-radius: 99px;
}
.message-loading-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

@media only screen and (min-width: 1280px) {
    .chats-desktop-sidebar{
        width: 20%;
    }
    .chats-desktop-content{
        width: 80%;
    }
}
